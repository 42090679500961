import api from "../services/api";

export const currencyFormatter = (amount, currency = "USD") => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  });
  return formatter.format(amount);
};

export const isClientOfTypePerson = (client) => client.client_type === 'person';

/*******************************/
/** document upload procedure **/
/*******************************/
export const uploadDocuments = (params) => {
  if (!params.documents) {
    return null;
  }
  const promises = [];
  const documentName = params?.client.display_name || params?.client.id;
  for (const file of params.documents) {
    const formdata = new FormData();
    const fileName = file.name.split('.')[0]
    formdata.append("document_type", `${params.docType}`);
    formdata.append("workflow", `${params.workFlow}`);
    formdata.append("document_source", params.documentSource);
    formdata.append("client_id", params?.client.id);
    formdata.append("file", file);
    formdata.append("document_name", fileName);
    promises.push(api.upload("/advisor_portal/upload_document", formdata));
  }
  return Promise.allSettled(promises);
};

export const getOutSystemsURL = (firm) => {
  try {
    return localStorage.getItem('outSystemsUrl').replace('firm', firm);
  } catch (err) {
    throw new Error('Error building OutSystems URL:', err);
  }
}
