<template>
  <PasPageTitle class="page-header">
    <router-link :to="path" v-if="path && !isExternalLink(path)">
      <v-icon class="page-header-icon">mdi-chevron-left</v-icon>
      <span class="page-header-title">
        <slot></slot>
      </span>
    </router-link>
    <a :href="path" v-else-if="path && isExternalLink(path)">
      <v-icon class="page-header-icon">mdi-chevron-left</v-icon>
      <span class="page-header-title">
        <slot></slot>
      </span>
    </a>
    <div v-else>
      <v-icon class="page-header-icon">mdi-chevron-left</v-icon>
      <span class="page-header-title">
        <slot></slot>
      </span>
    </div>
  </PasPageTitle>
</template>

<script>

export default {
  name: 'PageHeader',
  props: {
    path: String,
  },
  computed: {
    router() {
      return this.$router;
    },
  },
   methods: {
    isExternalLink(link) {
      return link.startsWith('http://') || link.startsWith('https://');
    }
  }
};
</script>

<style lang="scss">
i {
  cursor: pointer;
}
.page-header {
  width: 80% !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}

.page-header-title {
  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
}
.page-header-icon {
  padding-bottom: 5px !important;
}

</style>
