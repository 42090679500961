import Vue from 'vue';
import _ from 'lodash';
import VueRouter from 'vue-router';
import Store from 'Store/store';
import { getTheme } from 'Services/ThemeHelpers/ThemeHelpers';
import Dashboard from 'Components/Vue-Routes/Dashboard';
import DashboardV2 from 'Components/Vue-Routes/DashboardV2';
import MyNotifications from 'Components/Vue-Routes/MyNotifications';
import PortfolioDetails from 'Components/Vue-Routes/PortfolioDetails';
import MyTransfers from 'Components/Vue-Routes/MyTransfers';
import MySettings from 'Components/Vue-Routes/MySettings';
import PageLoader from 'Components/Vue-Routes/PageLoader';
import RolesAndPermissionsManager from 'Components/Vue-Routes/RolesAndPermissionsManager';
import PlatformUsersManager from 'Components/Vue-Routes/PlatformUsersManager';
import RAPreferencesManager from 'Components/Vue-Routes/RAPreferencesManager';
import ClientConfirmation from 'Components/Vue-Routes/ConfirmationTool';
import MyMoneyMovements from 'Components/Vue-Routes/MyMoneyMovements';
import FundingPage from 'Components/Vue-Routes/FundingPage/FundingPage';
import Regulations from 'Components/Vue-Routes/Regulations';
import FATCARenewals from 'Components/Vue-Routes/FATCARenewals';
import Insights from 'Components/Vue-Routes/Insights';
import vuetify from '../styles/vuetify.lazy.scss';
import purposeful from '../styles/purposeful.lazy.scss';

import { clientRoutes } from './client.routes';
import { authRoutes } from './auth.routes';
import { workflowRoutes } from './workflow.routes';
import { householdRoutes } from './household.routes';
import { embedRoutes } from './embed.routes';

Vue.use(VueRouter);

let usingPurposeful = false;
let usingVuetify = false;

const storeInit = Store.dispatch('fetchUserInfoOnLogin');
const routes = [
  ...workflowRoutes,
  ...clientRoutes,
  ...authRoutes,
  ...householdRoutes,
  ...embedRoutes,
  /*-------------*/
  {
    path: '/money-movements',
    component: MyMoneyMovements,
    meta: {
      canAccess: () => Store.getters.canViewMoneyMovementConsolidatedView,
      accessRedirect: '/',
      stylesheet: 'old',
    },
  },
  {
    path: '/transfers',
    component: MyTransfers,
    meta: {
      stylesheet: 'old',
    },
  },
  {
    path: '/notifications',
    component: MyNotifications,
    meta: {
      stylesheet: 'old',
    },
  },
  {
    path: '/portfolio_details',
    component: PortfolioDetails,
    meta: {
      canAccess: () => Store.getters.canViewPortfolioDetails && Store.getters.hasFeatureFlag('model_portfolio_details'),
      stylesheet: 'old',
    },
  },
  {
    path: '/settings',
    component: MySettings,
    meta: {
      stylesheet: 'old',
    },
  },
  {
    path: '/',
    component: Dashboard,
    meta: {
      canAccess: () => !Store.getters.hasFeatureFlag('new_workflows'),
      accessRedirect: '/dashboardV2',
      stylesheet: 'old',
    },
  },
  {
    path: '/dashboardV2',
    component: DashboardV2,
    meta: {
      canAccess: () => Store.getters.hasFeatureFlag('new_workflows'),
      stylesheet: 'old',
    },
  },
  {
    path: '/loader',
    component: PageLoader,
    meta: {
      canAccess: () => Store.getters.canEditRolesAndPermissionsManager,
      stylesheet: 'old',
    },
  },
  {
    path: '/roles-and-permissions-manager',
    component: RolesAndPermissionsManager,
    meta: {
      canAccess: () => Store.getters.canEditRolesAndPermissionsManager,
      stylesheet: 'old',
    },
  },
  {
    path: '/platform-users-manager',
    component: PlatformUsersManager,
    meta: {
      canAccess: () => Store.getters.canEditPlatformUsersManager,
      stylesheet: 'old',
    },
  },
  {
    path: '/ra-preferences-manager',
    component: RAPreferencesManager,
    meta: {
      canAccess: () => Store.getters.canEditPlatformUsersManager,
      stylesheet: 'old',
    },
  },
  {
    path: '/client-confirmation',
    component: ClientConfirmation,
    meta: {
      stylesheet: 'old',
    },
  },
  {
    path: '/funding-page',
    component: FundingPage,
    meta: {
      stylesheet: 'beta',
    },
  },
  {
    path: '/regulations',
    component: Regulations,
    meta: {
      canAccess: () => Store.getters.canViewRegulationsPage && Store.getters.hasFeatureFlag('annual_kyc'),
      stylesheet: 'beta',
    },
  },
  {
    path: '/fatca-renewals',
    component: FATCARenewals,
    meta: {
      canAccess: () => Store.getters.hasFeatureFlag('fatca_renewals'),
      stylesheet: 'beta',
    },
  },
  {
    path: '/insights',
    component: Insights,
    meta: {
      canAccess: () => Store.getters.hasFeatureFlag('insights') && Store.getters.canAccessInsights,
      stylesheet: 'beta',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/advisor_portal/',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.stylesheet === 'beta' && !usingPurposeful) {
    console.log('USE PURPOSEFUL');
    purposeful.use();
    vuetify.unuse();
    usingPurposeful = true;
    usingVuetify = false;
  } else if (to.meta.stylesheet === 'old' && !usingVuetify) {
    console.log('USE VUETIFY');
    vuetify.use();
    purposeful.unuse();
    usingPurposeful = false;
    usingVuetify = true;
  }

  const { currentTheme } = Store.getters;
  if (!currentTheme || currentTheme === '') Store.dispatch('setTheme', getTheme());
  else if (currentTheme !== getTheme() && !(Store.state.app.env === 'local')) {
    // after setting a theme, theme should not change within a given Vue instance.
    // If it does, something funky's happened so log out
    Store.dispatch('logUserOut');
    next('/login');
    return;
  }

  // if first time routing, call store init function to populate data
  // this will call the promise once, set the resolved value and never get called again
  const jwt = localStorage.getItem('jwt');
  if (jwt) {
    if (to.meta.requiresAuth === false) {
      next('/');
    }
    storeInit.then(() => {
      // check if user has access to route
      if (to.meta && to.meta.canAccess && to.meta.canAccess() === false) {
        // if a redirect param is set up based on access, go to that route
        if (to.meta.accessRedirect) {
          // make sure all params are replaced
          const paramNames = _.keys(to.params);
          let redirectUrl = to.meta.accessRedirect;
          paramNames.forEach((paramName) => {
            redirectUrl = _.replace(redirectUrl, `:${paramName}`, `${to.params[paramName]}`);
          });

          next({ path: redirectUrl, query: to.query });
        } else {
          next('/');
        }
      } else {
        next();
      }
    }).catch((e) => {
      console.log(e);
      next(false);
    });
  } else {
    Store.dispatch('logUserOut');
    next();
  }
});

export default router;
