<template>
    <div v-if="clientId">
      <PasPageHeader :path="url">
        {{clientDisplayName}}
      </PasPageHeader>
      <div class="tabs-page-view">
        <PasPageSubtitle class="px-2 py-4">Discussion</PasPageSubtitle>
        <PasDiscussion
          :categoryId="1"
          :clientId="clientId"
          :maxChars="5000"
        />
      </div>
    </div>
  </template>


<script>
import PasDiscussion from 'Components/Shared/Discussion/Discussion';
import { getOutSystemsURL } from '../../../../../../helpers';

export default {
    name: 'notes',
    components: {
        PasDiscussion
    },
    computed: {
      activeClient() {
        return this.$store.state.clients.activeClient;
      },
      clientId() {
        return this.$store.state.clients.activeClient?.client?.id;
      },
      clientDisplayName() {
        return this.activeClient?.client?.display_name;
      },
      url() {
        const firm = this.$store.getters.firm;
        return `${getOutSystemsURL(firm)}/clients/${this.clientId}`;
      }
    }

};

</script>