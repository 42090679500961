import Store from 'Store/store';
import ClientMaintenance from 'Components/Vue-Routes/Embed/ClientMaintenance';
import ClientView from 'Components/Vue-Routes/Embed/MyClients/ClientView';
import ClientHome from 'Components/Vue-Routes/Embed/MyClients/ClientView/ClientHome';
import MyClients from 'Components/Vue-Routes/Embed/MyClients';
import AllClients from 'Components/Vue-Routes/Embed/MyClients/AllClients';
import ShareholderPreferences from 'Components/Vue-Routes/Embed/MyClients/ClientView/ClientEdit/ShareholderPreferences';
import NotesPage from 'Components/Vue-Routes/Embed/MyClients/ClientView/ClientEdit/NotesPage';
import FeeManagementTab from 'Components/Vue-Routes/Embed/MyClients/ClientView/ClientEdit/FeeManagementTab';

export const embedRoutes = [
  {
    path: '/embed',
    component: { render: (c) => c('router-view') },
    meta: {
      stylesheet: 'old'
    },
    children: [
      {
        path: 'clients',
        component: MyClients,
        meta: {
          stylesheet: 'old',
        },
        children: [
          {
            path: '/',
            component: AllClients,
            props: (route) => ({ ...route.query }),
            meta: {
              canAccess: () => Store.getters.canViewMyClientsBasicInformation,
              stylesheet: 'old',
            },
          },
          {
            path: ':clientID',
            component: ClientView,
            name: 'EmbedClientViewPage',
            meta: {
              stylesheet: 'old',
              requiresAuth: true,
            },
            children: [
              {
                path: '/',
                name: 'EmbedClientHomePage',
                component: ClientHome,
                meta: {
                  canAccess: () => Store.getters.canViewMyClientsBasicInformation,
                  stylesheet: 'old',
                },
              },
              {
                path: 'client-maintenance',
                name: 'ClientMaintenancePage',
                component: ClientMaintenance,
                meta: {
                  canAccess: () => Store.getters.canViewClientDetails && Store.getters.hasFeatureFlag('client_maintenance'),
                  stylesheet: 'beta',
                },
              },
              {
                path: 'shareholder-preferences',
                name: 'ShareholderPreferences',
                component: ShareholderPreferences,
                meta: {
                  stylesheet: 'old',
                },
              },
              {
                path: 'fee-management',
                name: 'FeeManagementTab',
                component: FeeManagementTab,
                meta: {
                  canAccess: () => Store.getters.canAccessFullClientProfile,
                  stylesheet: 'old',
                },
              },
              {
                path: 'notes',
                name: 'NotesPage',
                component: NotesPage,
                meta: {
                  canAccess: () => Store.getters.canAccessFullClientProfile && Store.getters.canEditNotes,
                  stylesheet: 'old',
                },
              },
            ]
          },
        ]
      }
    ],
  },
];
