<template>
  <div>
    <WorkflowStep :isCanceled="workflow.status === 'canceled'" :step="step" :alwaysAllowContent="true">
      <span v-if="isManualWorkflow && hasFullWorkflowEditPermission" @click.prevent="completeSigning" class="action-text">
        Mark as complete
      </span>
      <span v-if="!isManualWorkflow && (workflow.type !== 'ips_workflow' || canEditIpsWorkflow)" @click.prevent="openViewSignersModal" class="action-text">View Signers</span>
    </WorkflowStep>

    <MessageDialog
      @ok="viewSignersModalOpen = false"
      :isOpen="viewSignersModalOpen"
      :width="'500px'"
    >
      <template v-slot:header>Signers</template>
      <div v-if="isLoadingInfo">
        <v-progress-circular indeterminate />
      </div>
      <div v-if="signers.length === 0 && !isLoadingInfo">
        No signers found.
      </div>
      <div v-if="!isLoadingInfo">
        <div v-for="(signer, index) in signers" :key="signer.recipientId" class="signer-item">
          <div class="signer-header">
            <strong class="signer-name">{{ signer.name }}</strong>
            <span class="status-badge" :class="getStatusClass(signer.status)">
              {{ getStatusClassString(signer.status) }}
            </span>
          </div>
          <div class="signer-details">
            <template v-if="signer.status === 'sent'">
              Sent on {{ formatDateTime(signer.sentDateTime) }}
            </template>
            <template v-else-if="signer.status === 'delivered'">
              Last opened on {{ formatDateTime(signer.deliveredDateTime) }}
            </template>
            <template v-else-if="signer.status === 'completed'">
              Signed on {{ formatDateTime(signer.signedDateTime) }}
            </template>
          </div>
          <div v-if="signer.status === 'sent' || signer.status === 'delivered'" class="resend-link">
            <a href="#" @click.prevent="resendPackage(signer)">RESEND</a>
          </div>
          <hr v-if="index < signers.length - 1" class="separator" />
        </div>
      </div>
      <template v-slot:btn>Close</template>
    </MessageDialog>

    <MessageDialog
      @ok="resendModalOpen = false"
      :isOpen="resendModalOpen"
      :width="'500px'"
    >
      <template v-slot:header>Resending package</template>
      <div v-if="isLoadingInfo">
        <v-progress-circular indeterminate />
      </div>
      <div v-if="resendModalOpen && !isLoadingInfo">
        Signature request has been successfully sent to {{ resendSigner.name }}.
      </div>
      <template v-slot:btn>Done</template>
    </MessageDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sendDocumentSigningSuccess, resendDocuSignPackage, getSigners } from 'Services/api/Workflows';
import MessageDialog from 'Components/Shared/MessageDialog';
import WorkflowStep from './WorkflowStep';

export default {
  name: 'ClientSigningStep',
  components: { WorkflowStep, MessageDialog },
  props: {
    workflow: {
      type: Object,
      required: true,
    },
    step: {
      type: Object,
      default: () => {},
    },
    isManualWorkflow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resendModalOpen: false,
      isLoadingInfo: false,
      viewSignersModalOpen: false,
      signers: [],
      resendSigner: null,
    };
  },
  computed: {
    ...mapGetters([
      'activeClient',
      'hasFullWorkflowEditPermission',
      'canEditIpsWorkflow',
    ]),
  },
  async mounted() {
    this.signers = [];
    this.fetchSigners();
  },
  methods: {
    async fetchSigners() {
      try {
        const res = await getSigners(this.workflow);
        if (res.status === 200) {
          this.signers = res.signers;
        } else {
          this.signers = [];
        }
      } catch (err) {
        // silently disregard error
        this.signers = [];
      }
    },
    formatDateTime(dateTime) {
      if (!dateTime) return '';
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return new Date(dateTime).toLocaleString('en-US', options);
    },
    getStatusClassString(status) {
      switch (status.toLowerCase()) {
        case 'sent':
          return 'Pending Signature';
        case 'delivered':
          return 'Pending Signature';
        case 'completed':
          return 'Completed';
        default:
          return 'Pending Invite';
      }
    },
    getStatusClass(status) {
      switch (status.toLowerCase()) {
        case 'sent':
          return 'badge-sent';
        case 'delivered':
          return 'badge-delivered';
        case 'completed':
          return 'badge-complete';
        default:
          return 'badge-default';
      }
    },
    openResendModal() {
      this.resendModalOpen = true;
    },
    async openViewSignersModal() {
      this.signers = [];
      this.isLoadingInfo = true;
      this.viewSignersModalOpen = true;
      await this.fetchSigners();
      this.isLoadingInfo = false;
    },
    async completeSigning() {
      try {
        const res = await sendDocumentSigningSuccess(this.workflow, 'client');
        if (res.status === 200) {
          this.$store.dispatch('setSnackbar', {
            text: 'Updating Workflow',
            type: 'success',
          });
          this.$store.dispatch('flipSnackbarSwitch');
          await this.$store.dispatch('fetchWorkflowV2Steps', this.workflow.id);
        }
      } catch (err) {
        console.log(err);
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to update workflow',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
    async resendPackage(signer) {
      try {
        this.viewSignersModalOpen = false;
        this.resendModalOpen = true;
        this.isLoadingInfo = true;
        this.resendSigner = signer;
        await resendDocuSignPackage(this.workflow);
        this.isLoadingInfo = false;
      } catch (err) {
        this.resendModalOpen = false;
        this.$store.dispatch('setSnackbar', {
          text: 'Fail to resent package',
          type: 'error',
        });
        this.$store.dispatch('flipSnackbarSwitch');
      }
    },
  },
};
</script>

<style scoped>
  .signer-item {
    margin-bottom: 16px;
  }

  .signer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .signer-name {
    font-weight: bold;
  }

  .status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.875rem;
    text-transform: capitalize;
  }

  .badge-sent {
    background-color: var(--warning-light, #FFEC77);
    color: var(--warning, #C23400);;
  }

  .badge-delivered {
    background-color: var(--warning-light, #FFEC77);
    color: var(--warning, #C23400);;
  }

  .badge-complete {
    background-color: #C8FFEA;;
    color: #007A4B;;
  }

  .badge-default {
    background-color: var(--tertiary, #F3F7F7);;
    color: var(--text-medium, #657381);;
  }

  .signer-details {
    margin-top: 8px;
    font-size: 0.875rem;
    color: #555;
  }

  .resend-link {
    margin-top: 8px;
    font-size: 0.875rem;
    color: #007bff;
  }

  .resend-link a {
    text-decoration: none;
    cursor: pointer;
  }

  .resend-link a:hover {
    text-decoration: underline;
  }
  .separator {
    border: none;
    border-top: 1px solid #ccc;
    margin: 16px 0;
  }
</style>
